import React from 'react';
import { Axios } from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, FormValues } from "./declarations/form";
import { Box, Typography, CircularProgress, Backdrop } from "@mui/material";
import { useForm } from "react-hook-form";
import { useCallback, useMemo, useState } from "react";
import { PriceCheckApiData } from "./declarations/apiRequests";
import { ControlledInput } from "./ControlledInput";
import { LicenseField } from "./LicenseField";
import { OrderTotal } from "./OrderTotal";
import { PaymentForm } from "./PaymentForm";
import { License, PriceCheck } from "./declarations/license";
import { schema } from "./declarations/yup";
import { useStripe,
         useElements } from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom';
import { CustomDialog } from "./CustomDialog";
import { ErrorDialog, RequestErrorMessage, requestErrors,
         paymentErrors } from "./declarations/errors";
import * as Constants from "./declarations/constants";

export interface FormBodyProps {
  formType: string;
}

function FormBody({
  formType
}: FormBodyProps) {

  const [licenses, setLicenses] = useState<License[]>([]);
  const [priceCheck, setPriceCheck] = useState<PriceCheck>({
    price: formType.includes("personal") ? Constants.PERSONAL_PRICE : Constants.BUSINESS_PRICE,
    fullPrice: formType.includes("personal") ? Constants.PERSONAL_PRICE : Constants.BUSINESS_PRICE,
  } as PriceCheck);
  const [numLicensesField, setNumLicensesField] = useState(1);
  const [errorDialog, setErrorDialog] = useState<ErrorDialog>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const axios: Axios = require("axios");

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  //Send form data to the backend and have it send the license (or licenses) to the user.
  const sendLicense = async (formData: FormValues, firstName: string = '', lastName: string = '', email: string = '') => {
    console.log('sendLicense()');

    const first_name = formData.firstName.trim() || firstName;
    const last_name = formData.lastName.trim() || lastName;
    const email_address = formData.email.trim() || email;

    if (formType.includes('personal')) {
      try {
        await axios.post("/purchase/personal", {
          first_name: first_name,
          last_name: last_name,
          email: email_address,
          where_heard: formData.whereHeard.trim(),
          address_for_invoice: formData.addressForInvoice.trim(),
          vat_number_for_invoice: formData.vatNumberForInvoice.trim(),
          num_licenses: 1,
        });
      }
      catch (e: any) {
        setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
        return;
      }
    }
    else {
      try {
        await axios.post("/purchase/business", {
          first_name: first_name,
          last_name: last_name,
          company_name: formData.company.trim(),
          email: email_address,
          where_heard: formData.whereHeard.trim(),
          address_for_invoice: formData.addressForInvoice.trim(),
          vat_number_for_invoice: formData.vatNumberForInvoice.trim(),
          num_licenses: formData.numLicenses,
        });
      }
      catch (e: any) {
        setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
        return;
      }
    }

    //Go to the success page.
    navigate('/success');
  }

  //PayPal
    //Click
  const handleClick = () => {
    setIsSubmitting(true);
  };

    //Error
  const handleError = () => {
    console.log("handleError");
    setIsSubmitting(false);
    setErrorDialog(paymentErrors["Payment Error"]);
  };

    //Cancel
  const handleCancel = () => {
    console.log("handleCancel");
    setIsSubmitting(false);
  };

  //PayPal Approval
  const handleApprove = (firstName: string, lastName: string, email: string) => {
    setIsSubmitting(true);
    sendLicense(getValues(), firstName, lastName, email);
    setIsSubmitting(false);
  };

  //Stripe Approval
  const onSubmitButton = async (data: FormValues) => {
    // setProcessing(true);

    //Free License
    if (priceCheck.price === 0)
    {
      sendLicense(data);
      return;
    }

    //Handle Stripe.
    if (!stripe || !elements) {
      return;
    }

    console.log("Stripe submitted.");
    setIsSubmitting(true);

    //Update the price (really only needed for single business license w/ no discount).
    const axios: Axios = require("axios");
    let response;
    try {
      response = await axios.post("/check_price", {
        license_type: formType.includes("personal") ? "personal2" : "business2",
      });
    } catch (e: any) {
      setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
      setIsSubmitting(false);
      return;
    }

    const responseData: PriceCheckApiData = response?.data;
    setPriceCheck({
      price: responseData.price,
      fullPrice: responseData.full_price
    });

    const payload = await stripe.confirmPayment({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: data.firstName + " " + data.lastName,
            email: data.email,
          }
        },
      },
      redirect: 'if_required'
    });


    if (payload.error) {
      console.log("Payment failed.");
      setErrorDialog(paymentErrors["Payment Error"]);
    } else {
      console.log("Payment succeeded.");
      sendLicense(data);
    }

    setIsSubmitting(false);
    console.log("PaymentForm handleSubmit(): priceCheck.price = " + priceCheck.price);
  };

  const gridContainer = useMemo(() => {
    return {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridGap: 20,
    };
  }, []);

  const fullWidthRow = useMemo(() => {
    return { gridColumnStart: 1, gridColumnEnd: 3 };
  }, []);
  const firstHalfWidthRow = useMemo(() => {
    return { gridColumnStart: 1, gridColumnEnd: 2 };
  }, []);

  const lastHalfWidthRow = useMemo(() => {
    return { gridColumnStart: 2, gridColumnEnd: 3 };
  }, []);

  const handleLicenseChange = useCallback(
    (newLicenses: License[], priceCheck: PriceCheck) => {
      setLicenses(newLicenses);
      setPriceCheck(priceCheck);
    }, []);

  useCallback(
    (numLicenses: number) => {
      setNumLicensesField(numLicenses);
    }, []);

  const handleNumLicensesChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const numLicensesTemp = parseInt(event.target.value);
    const isNum = !Number.isNaN(numLicensesTemp);
    if (isNum && numLicensesTemp > 0) {
      setNumLicensesField(numLicensesTemp);
      changePriceWithQuantity(numLicensesTemp);
    }
  };

  //Update the price when the quantity is set.
  const changePriceWithQuantity = async (num_licenses: number) => {

    //Check price.
    console.log("changePriceWithQuantity: num_licenses = " + num_licenses);

    let response;
    try {
      response = await axios.post("/set_num_licenses", {
        num_licenses: num_licenses,
      });
    } catch (e: any) {
      setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
      return;
    }

    try {
      response = await axios.post("/check_price", {
        license_type: "business2",
      });
    } catch (e: any) {
      setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
      return;
    }

    const data: PriceCheckApiData = response?.data;
    setPriceCheck({
      price: data.price,
      fullPrice: data.full_price
    });
    console.log("Just set price.");
  }


  return (
    <Box sx={{ px: 0, py: 4, }}>
      <CustomDialog
        title={errorDialog?.title}
        body={errorDialog?.message}
        opened={!!errorDialog}
      />
      <form onSubmit={handleSubmit(onSubmitButton)}>
        <Box sx={gridContainer}>
          {(formType === "personal-upgrade") &&
            <Typography sx={fullWidthRow} variant="body2">
              Enter your version 1 license key to receive a discount on your upgrade.<br/>
              You can paste the license key, but you only need the 32 character string at the end.<br/>
              If you think you're eligible for a discount and it says you are not, please contact us <a href="https://www.moderncsv.com/contact-us">here</a>.<br/>
            </Typography>
          }
          {(formType === "business-upgrade") &&
            <Typography sx={fullWidthRow} variant="body2">
              Enter your version 1 license keys to receive a discount on your upgrade.<br/>
              You can paste multiple license keys.<br/>
              You can paste entire license keys, but you only need the 32 character string at the end.<br/>
              If you think you're eligible for a discount and it says you are not, please contact us <a href="https://www.moderncsv.com/contact-us">here</a>.<br/>
            </Typography>
          }
          {(formType === "personal-upgrade" || formType === "business-upgrade") &&
            <LicenseField
              containerSX={fullWidthRow}
              name="license"
              control={control}
              label={"License key" + ((formType === 'business-upgrade') ? "s" : "") + " (optional)"}
              rows={4}
              onLicenseChange={handleLicenseChange}
              formType={formType}
              multiline
            />
          }
          <ControlledInput
            sx={firstHalfWidthRow}
            name="firstName"
            control={control}
            label="First Name"
            error={errors.firstName}
            required
          />
          <ControlledInput
            sx={lastHalfWidthRow}
            name="lastName"
            control={control}
            label="Last name"
            error={errors.lastName}
            required
          />
          {(formType === "business" || formType === "business-upgrade") &&
            <ControlledInput
              sx={fullWidthRow}
              name="company"
              control={control}
              label="Company (optional)"
              error={errors.company}
            />
          }
          <ControlledInput
            sx={fullWidthRow}
            name="email"
            control={control}
            label="Email"
            error={errors.email}
            required
          />
          {(formType === "business" || formType === "business-upgrade") &&
            //https://stackoverflow.com/a/64933799
            <ControlledInput
              sx={firstHalfWidthRow}
              name="numLicenses"
              control={control}
              type="number"
              label="Number of licenses"
              error={errors.numLicenses}
              onChange={handleNumLicensesChange}
              inputProps={{ min: "1", step: "1", pattern: "([^0-9]*)" }}
              value={numLicensesField}
              required
            />
          }
          {(formType === "personal" || formType === "business") &&
            <ControlledInput
              sx={fullWidthRow}
              name="whereHeard"
              control={control}
              label="Where did you hear about us? (optional)"
              error={errors.whereHeard}
            />
          }
          <ControlledInput
            sx={fullWidthRow}
            name="addressForInvoice"
            control={control}
            label="Address for Invoice (optional)"
            rows={3}
            error={errors.addressForInvoice}
            multiline
          />
          <ControlledInput
            sx={fullWidthRow}
            name="vatNumberForInvoice"
            control={control}
            label="VAT Number for Invoice (optional)"
            error={errors.vatNumberForInvoice}
          />

          <OrderTotal
            sx={fullWidthRow}
            licenses={licenses}
            priceCheck={priceCheck}
            onChange={setPriceCheck}
            formType={formType}
          />
        </Box>
        <Box mb={5}></Box>
          <PaymentForm
            sx={fullWidthRow}
            licenses={licenses}
            priceCheck={priceCheck}
            onPriceChange={setPriceCheck}
            onClick={handleClick}
            onApprove={handleApprove}
            onError={handleError}
            onCancel={handleCancel}
          />
      </form>
      <Backdrop
        sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
        // onClick={handleClose}
      >
        <CircularProgress sx={{color: 'blue'}} />
      </Backdrop>
    </Box>
  );
}

export default FormBody;
