import { Box, Button } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { License, PriceCheck } from "./declarations/license";
import { StripePayment } from "./StripePayment";
import { PayPalPayment } from "./PayPalPayment";
import { Divider } from "@mui/material";

export interface PaymentFormProps {
  licenses: License[];
  priceCheck: PriceCheck;
  onPriceChange?: (orderValue: PriceCheck) => void;
  sx?: Record<string, object | string | number>;
  onClick: () => void;
  onApprove: (firstName: string, lastName: string, email: string) => void;
  onError: () => void;
  onCancel: () => void;
}

export function PaymentForm({
  licenses,
  priceCheck,
  sx,
  onPriceChange: handleParentPriceChange,
  onClick,
  onApprove,
  onError,
  onCancel,
}: PaymentFormProps) {

  const [buttonText, setButtonText] = useState("Submit Payment");
  const [showPaymentForm, setShowPaymentForm] = useState(true);

  useEffect(() => {
    handleParentPriceChange && handleParentPriceChange(priceCheck);
    console.log("PaymentForm handleParentPriceChange(): priceCheck.price = " + priceCheck.price);
    if (priceCheck.price === 0)
    {
      setButtonText("Get Free License");
      setShowPaymentForm(false);
    }
    else
    {
      setButtonText("Submit Payment");
      setShowPaymentForm(true);
    }
  }, [priceCheck, handleParentPriceChange]);

  const innerBoxStyle = useMemo(() => {
    return {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    };
  }, []);

  const gridContainer = useMemo(() => {
    return {
      display: "grid",
      gridTemplateColumns: "repeat(1, 1fr)",
      gap: 2,
    };
  }, []);

  return (
    <Box sx={gridContainer}>
      {showPaymentForm &&
        <Box>
          <PayPalPayment
            licenses={licenses}
            priceCheck={priceCheck}
            onClick={onClick}
            onApprove={onApprove}
            onError={onError}
            onCancel={onCancel}
          />
        </Box>
      }
      {showPaymentForm &&
        <div>
          <Divider textAlign="center">or</Divider>
        </div>
      }
      {showPaymentForm &&
        <Box>
          <StripePayment
            licenses={licenses}
            priceCheck={priceCheck}
          />
        </Box>
      }

      <Box sx={innerBoxStyle}>
        <Button variant="contained" size="large" type="submit">
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
}
